<template>
  <ion-page>
    <WhiteHeader :back-button="false" title="Mes bons de location" />
    <ion-item>
      <ion-icon :icon="calendarNumberOutline"></ion-icon>
      <ion-select
        @click="$emit('update:initialized', true)"
        v-model="selectedDate"
        placeholder="Aucun mois"
        ok-text="Valider"
        cancel-text="Annuler"
        interface="action-sheet"
      >
        <ion-select-option
          v-for="date in dates"
          :value="date[0]"
          :key="date[0]"
        >
          {{ date[1] }}
        </ion-select-option>
      </ion-select>
    </ion-item>
    <ion-content>
      <ion-refresher
        class="refresher refresher-coupons"
        slot="fixed"
        @ionRefresh="resetAndRefresh($event)"
        @click="resetAndRefresh($event)"
        ><ion-refresher-content></ion-refresher-content
      ></ion-refresher>
      <ion-label
        v-if="!(listRentalCoupon && listRentalCoupon.length)"
        class="align-text-center-screen"
        >Aucun BL créé sur {{ formattedDate }}</ion-label
      >
      <rental-coupon
        v-else
        v-for="rentalCoupon in listRentalCoupon"
        :type-mission="getTypeRentalCoupon(rentalCoupon)"
        :rental-coupon="rentalCoupon"
        v-bind:key="rentalCoupon.id"
      />
      <ion-row v-if="nextPageUrl" class="align-center mb-10">
        <ion-button color="primary" @click="loadMore()">
          Charger plus
        </ion-button>
      </ion-row>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonLabel,
  IonRefresher,
  IonRefresherContent,
  IonItem,
  IonSelect,
  IonSelectOption,
  IonButton,
  IonRow,
  IonIcon,
  loadingController,
} from '@ionic/vue';
import WhiteHeader from '@/components/driver/header/WhiteHeader';
import { getInfo } from '@/services/users';
import { allRentalCoupon } from '@/services/driver/rentalCoupon';
import RentalCoupon from '@/components/driver/card/RentalCoupon';
import { ref } from 'vue';
import { taskToDo } from '@/services/utils/dateUtils';
import { calendarNumberOutline } from 'ionicons/icons';
import utils from '@/services/utils/Utils';

export default {
  name: 'AllRentalCoupon',
  components: {
    RentalCoupon,
    WhiteHeader,
    IonPage,
    IonContent,
    IonLabel,
    IonRefresher,
    IonRefresherContent,
    IonItem,
    IonSelect,
    IonSelectOption,
    IonButton,
    IonRow,
    IonIcon,
  },
  data() {
    return {
      agent: {},
      listRentalCoupon: [],
      selectedDate: new Date().toISOString().slice(0, 7),
      nextPageUrl: null,

      // allow to not refresh our list. for example when we check for coupon, then go back to the list
      freezeRefresh: false,
    };
  },
  setup() {
    const content = ref();

    /**
     * Allows to know if the mission corresponds to the mission of the day
     */
    const toDo = end => {
      let info = taskToDo(end);
      return info;
    };

    return { content, toDo, calendarNumberOutline };
  },

  ionViewDidEnter() {
    if (this.$route.query.forceRefresh) {
      this.resetAndRefresh();
      // avoid always refresh, even when back without saving
      this.$router.replace({
        query: {},
      });
    }
  },

  // Stop auto-refresh when page quits
  ionViewDidLeave() {
    // allow to keep our list when we left the view
    this.freezeRefresh = true;
  },

  async mounted() {
    const { userData } = await getInfo();
    this.agent = userData.agent;
    this.doRefresh();
  },

  methods: {
    getTypeRentalCoupon(rentalCoupon) {
      if (rentalCoupon.kind === 'transfer') {
        return 'transfert';
      } else if (rentalCoupon.kind === 'delivery') {
        return 'livraison';
      }
      return 'reservation';
    },

    async doRefresh(event) {
      if (!this.agent || !this.agent.id || this.freezeRefresh) return;

      // display loading while fetching data
      const loading = await loadingController.create({
        message: 'Chargement en cours...',
      });

      try {
        await loading.present();

        const response = await allRentalCoupon(
          this.agent.id,
          this.selectedDate,
          this.nextPageUrl,
        );

        // avoid duplicated entries
        const ids = this.listRentalCoupon.map(coupon => coupon.id);
        const filtered = response['data'].filter(
          coupon => ids.indexOf(coupon.id) === -1,
        );

        this.listRentalCoupon = this.listRentalCoupon.concat(filtered);

        // if we are a the last array possible
        // this.loadMoreShown = response.current_page !== response.last_page;
        this.nextPageUrl = response.next_page_url;
      } catch {
        console.log('Unable to refresh rental coupon list');
      }

      if (event && event.target && event.target.complete) {
        event.target.complete();
      }

      // by the end, dismiss loader
      await loading.dismiss();
    },

    loadMore() {
      this.freezeRefresh = false;
      this.doRefresh();
    },

    resetAndRefresh(event = null) {
      this.nextPageUrl = null;
      this.freezeRefresh = false;
      this.listRentalCoupon = [];

      this.doRefresh(event);
    },
  },

  watch: {
    selectedDate() {
      this.resetAndRefresh();
    },
  },

  computed: {
    dates() {
      const months = [];

      utils.generateArray(6, 1, 0).forEach(index => {
        // first increment start
        const date = new Date();
        date.setMonth(date.getMonth() - index);

        // format date to display (ex: 'Janvier 2024')
        const displayed = date.toLocaleDateString('default', {
          month: 'long',
          year: 'numeric',
        });

        // need to add one to month because it's not yet incremented
        // padStart allow to not have '1' but '01'
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const key = `${date.getFullYear()}-${month}`;

        // Capitalize displayed
        months[key] = displayed.charAt(0).toUpperCase() + displayed.slice(1);
      });

      // Convert object to array
      return Object.entries(months);
    },

    /**
     * Get date to display if no coupons found for the selected month
     */
    formattedDate() {
      if (!this.dates) return '';

      const date = this.dates.filter(date => date[0] === this.selectedDate);

      if (!date || !date.length) return '';

      return date[0][1].toLowerCase();
    },
  },
};
</script>

<style scoped>
ion-item {
  --border-style: none;

  ion-icon {
    margin-right: 8px;
  }
}

.ion-page {
  align-items: flex-end;
}
</style>
