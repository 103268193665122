<template>
  <ion-page>
    <WhiteHeader
      :back-button="true"
      :route-back="routeBack"
      title="Rapport mission"
    />

    <ion-content class="ion-padding">
      <h4>Sélectionnez le jour</h4>

      <ion-row class="ion-justify-content-center">
        <ion-button
          v-for="(value, displayable) in missionDays"
          :key="value"
          :fill="currentDay === value ? 'solid' : 'outline'"
          size="small"
          color="dark"
          @click="onDateClick(value)"
        >
          {{ displayable }}
        </ion-button>
      </ion-row>

      <div v-if="currentDay">
        <ion-grid v-for="(question, key) in questions" :key="'question' + key">
          <h2 @click="open(question.title)">
            {{ question.title }}
            <ion-icon
              :icon="opened === question.title ? chevronUp : chevronDown"
            ></ion-icon>
          </h2>

          <ion-grid
            v-for="field in question.fields"
            :key="field.property"
            v-show="opened === question.title"
          >
            <h5>{{ field.name }}</h5>

            <!-- TEXTAREA -->
            <ion-row
              v-if="
                field.kind === questionKinds.TEXTAREA && reports[currentDay]
              "
            >
              <ion-textarea
                v-if="field.property"
                placeholder="Saisir..."
                :auto-grow="true"
                v-model="reports[currentDay][field.property]"
              ></ion-textarea>

              <ion-textarea
                v-else-if="reports[currentDay].custom_questions"
                placeholder="Saisir..."
                :auto-grow="true"
                v-model="reports[currentDay].custom_questions[field.uniqid]"
              ></ion-textarea>
            </ion-row>

            <!-- BOOLEAN -->
            <ion-row
              v-if="field.kind === questionKinds.BOOLEAN && reports[currentDay]"
            >
              <ion-segment
                :value="getValueForField(field)"
                @ionChange="onBooleanChange($event.target.value, field)"
                mode="ios"
              >
                <ion-segment-button
                  v-for="(value, displayable) in booleanValues"
                  :key="value + displayable"
                  class="width-segment-form-driver"
                  :value="value"
                >
                  <ion-label class="fs-12">{{ displayable }}</ion-label>
                </ion-segment-button>
              </ion-segment>
            </ion-row>

            <!-- DATERANGE -->
            <ion-grid v-if="field.kind === questionKinds.DATERANGE">
              <ion-row class="ion-align-items-center">
                <ion-label>Heure de début&nbsp;:</ion-label>

                <ion-button
                  :id="'btn-start' + field.property"
                  size="small"
                  class="ion-margin-start"
                >
                  {{ getDateTimeButtonLabel(field.property, 0) }}
                </ion-button>

                <ion-button
                  v-show="reports[currentDay][field.property][0]"
                  size="small"
                  color="danger"
                  @click="setNull(currentDay, field.property, 0)"
                >
                  <ion-icon slot="icon-only" :icon="trashOutline"></ion-icon>
                </ion-button>

                <ion-modal
                  :trigger="'btn-start' + field.property"
                  @willPresent="setNow(currentDay, field.property, 0)"
                  class="custom-modal"
                >
                  <ion-datetime
                    :show-default-buttons="true"
                    done-text="Valider"
                    cancel-text="Supprimer"
                    :id="'start-datetime' + field.property"
                    v-model="reports[currentDay][field.property][0]"
                    @ion-cancel="setNull(currentDay, field.property, 0)"
                    presentation="date-time"
                    :prefer-wheel="true"
                  ></ion-datetime>
                </ion-modal>
              </ion-row>

              <ion-row class="ion-align-items-center ion-margin-top">
                <ion-label>Heure de fin&nbsp;:</ion-label>
                <ion-button
                  :id="'btn-end' + field.property"
                  size="small"
                  class="ion-margin-start"
                >
                  {{ getDateTimeButtonLabel(field.property, 1) }}
                </ion-button>

                <ion-button
                  v-show="reports[currentDay][field.property][1]"
                  size="small"
                  color="danger"
                  @click="setNull(currentDay, field.property, 1)"
                >
                  <ion-icon slot="icon-only" :icon="trashOutline"></ion-icon>
                </ion-button>

                <ion-modal
                  :trigger="'btn-end' + field.property"
                  @willPresent="setNow(currentDay, field.property, 1)"
                  class="custom-modal"
                >
                  <ion-datetime
                    :show-default-buttons="true"
                    done-text="Valider"
                    cancel-text="Supprimer"
                    :id="'end-datetime' + field.property"
                    v-model="reports[currentDay][field.property][1]"
                    @ion-cancel="setNull(currentDay, field.property, 1)"
                    presentation="date-time"
                    :prefer-wheel="true"
                  ></ion-datetime>
                </ion-modal>
              </ion-row>
            </ion-grid>

            <!-- TIMESTAMP_NUMBER -->
            <ion-grid v-if="field.kind === questionKinds.TIMESTAMP_NUMBER">
              <ion-input
                type="number"
                step="0.01"
                v-model="reports[currentDay][field.property]"
              ></ion-input>

              <ion-row class="ion-align-items-center">
                <ion-label>Horodatage&nbsp;:</ion-label>

                <ion-button
                  :id="'btn-day' + field.property"
                  size="small"
                  class="ion-margin-start"
                >
                  {{ getDateTimeButtonLabel(field.property + '_timestamp') }}
                </ion-button>

                <ion-modal
                  :trigger="'btn-day' + field.property"
                  @willPresent="
                    setNow(currentDay, field.property + '_timestamp')
                  "
                  class="custom-modal"
                >
                  <ion-datetime
                    :show-default-buttons="true"
                    done-text="Valider"
                    cancel-text="Supprimer"
                    :id="'day-datetime' + field.property"
                    v-model="reports[currentDay][field.property + '_timestamp']"
                    @ion-cancel="
                      setNull(currentDay, field.property + '_timestamp')
                    "
                    presentation="date-time"
                    :prefer-wheel="true"
                  ></ion-datetime>
                </ion-modal>
              </ion-row>
            </ion-grid>

            <!-- NUMBER -->
            <ion-row
              v-if="field.kind === questionKinds.NUMBER"
              class="ion-align-items-center"
            >
              <ion-col>
                <ion-input
                  type="number"
                  step="0.01"
                  v-model="reports[currentDay][field.property]"
                ></ion-input>
              </ion-col>
              <ion-col class="ion-margin-start">
                {{ field.unit }}
              </ion-col>
            </ion-row>

            <!-- FILE -->
            <!-- if uniqid it's a custom question, else it's a "static" file type (e.g. "photo") -->
            <attachments
              v-if="field.kind === questionKinds.FILE && field.uniqid"
              v-model:attachments="
                reports[currentDay]['custom_questions'][field.uniqid]
              "
              v-model:waiting-files="waitingFiles"
              v-model:attachments-to-delete="attachmentsToDelete"
              v-model:attachments-to-update="attachmentsToUpdate"
              type-vue="create"
              :files-too-big="false"
              :kind="field.uniqid"
            />
            <attachments
              v-else-if="field.kind === questionKinds.FILE"
              v-model:attachments="reports[currentDay][field.property]"
              v-model:waiting-files="waitingFiles"
              v-model:attachments-to-delete="attachmentsToDelete"
              v-model:attachments-to-update="attachmentsToUpdate"
              type-vue="create"
              :files-too-big="false"
              :kind="field.property"
            />
          </ion-grid>
        </ion-grid>
      </div>
    </ion-content>

    <div v-if="currentDay" class="ion-padding bottom">
      <ion-button color="tertiary" expand="block" @click="save()">
        Enregistrer
      </ion-button>
      <ion-button expand="block" color="dark" class="mt-10" @click="save(true)">
        Enregistrer puis {{ couponId ? 'compléter' : 'remplir' }} le BL
      </ion-button>
    </div>
  </ion-page>
</template>

<script>
import WhiteHeader from '@/components/driver/header/WhiteHeader';
import {
  IonButton,
  IonCol,
  IonContent,
  IonDatetime,
  IonGrid,
  IonIcon,
  IonInput,
  IonLabel,
  IonModal,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonTextarea,
  loadingController,
  toastController,
} from '@ionic/vue';
import { getParams } from '@/services/users';
import questionKinds from '@/services/constants/questionKinds';
import utils from '@/services/utils/Utils';
import axios from 'axios';
import dayjs from 'dayjs';
import Attachments from '@/components/driver/rentalCoupon/components/Attachments.vue';
import { chevronDown, chevronUp, trashOutline } from 'ionicons/icons';

const MACHINE_FORMAT = 'YYYY-MM-DD[T]HH:mm';

export default {
  components: {
    IonButton,
    IonCol,
    IonContent,
    IonDatetime,
    IonGrid,
    IonIcon,
    IonInput,
    IonLabel,
    IonModal,
    IonRow,
    IonSegment,
    IonSegmentButton,
    IonTextarea,
    WhiteHeader,
    Attachments,
  },

  setup() {
    return {
      chevronDown,
      chevronUp,
      trashOutline,
      questionKinds,
    };
  },

  data() {
    return {
      routeBack: null,
      couponId: null,
      questions: {},
      reports: {},
      missionDays: [],
      currentDay: null,
      opened: null,
      sending: false,

      // files
      waitingFiles: [],
      attachmentsToDelete: [],
      attachmentsToUpdate: {},

      // static
      booleanValues: {
        'Oui': true,
        'Non': false,
        'Pas concerné': null,
      },
    };
  },

  mounted() {
    this.routeBack = this.$route.fullPath.split('/report')[0];
    this.couponId = this.$route.params.couponId;

    this.refreshQuestions();
  },

  methods: {
    // Always use replace to avoid going back to the report page
    goToGoodPage(createCoupon = false) {
      if (!createCoupon) {
        this.$router.replace(this.routeBack);
        return;
      }

      const currentPath = this.$route.fullPath;
      let link = '/';

      if (this.couponId) {
        const newPath = currentPath.replace('report', 'bonLocation');
        this.$router.replace(newPath);
        return;
      }

      if (currentPath.includes('chantier')) {
        link += 'chantier/' + this.$route.params.id;
        link += '/' + this.$route.params.idRide;
      } else {
        const kind = currentPath.includes('livraison')
          ? 'livraison'
          : 'transfert';

        link +=
          kind +
          '/' +
          this.$route.params.id +
          '/' +
          this.$route.params.idRide +
          '/' +
          this.$route.params.idItem;
      }

      link += '/bonLocation/create';
      this.$router.replace(link);
    },

    async refreshQuestions() {
      const dataToSend = this.getMissionRelatedData();

      try {
        const response = await axios.post(
          'driver/mission-report/list',
          dataToSend,
          await getParams(),
        );

        this.questions = response.data.questions;
        this.reports = response.data.reports;
        this.missionDays = response.data.mission_days;

        if (Object.keys(this.missionDays).length === 1) {
          this.onDateClick(Object.values(this.missionDays)[0]);
        }
      } catch (error) {
        this.sendToast(
          'Une erreur est survenue lors de la récupération des questions',
          'danger',
        );
      }
    },

    onDateClick(newDate) {
      this.currentDay = newDate;
      this.opened = Object.values(this.questions)[0].title;

      if (!this.reports[this.currentDay]) {
        this.reports[this.currentDay] = {};
      }

      if (!this.reports[this.currentDay].custom_questions) {
        this.reports[this.currentDay].custom_questions = {};
      }

      // get all fields in one big array
      const allFields = Object.values(this.questions).flatMap(
        question => question.fields,
      );

      // Init all answers to empty values when revelant,
      // or format them in some cases
      allFields.forEach(field => {
        const empty = this.getEmptyForField(field);

        if (!field.property) {
          if (this.reports[this.currentDay].custom_questions[field.uniqid])
            return;

          this.reports[this.currentDay].custom_questions[field.uniqid] = empty;
          return;
        }

        // When timestamp exists, format to machine format to avoid Ionic Picker not working
        if (this.reports[this.currentDay][field.property + '_timestamp']) {
          const tsKey = field.property + '_timestamp';
          const tsValue = this.reports[this.currentDay][tsKey];
          if (tsValue) {
            this.reports[this.currentDay][tsKey] =
              dayjs(tsValue).format(MACHINE_FORMAT);
          }
        }

        if (this.reports[this.currentDay][field.property]) return;

        this.reports[this.currentDay][field.property] = empty;
      });
    },

    getEmptyForField(field) {
      if (field.kind === questionKinds.DATERANGE) return [null, null];
      if (field.kind === questionKinds.FILE) return [];
      return null;
    },

    getMissionRelatedData() {
      if (this.couponId) {
        return {
          related_id: this.couponId,
          related_type: 'App\\RentalCoupon',
        };
      }

      const { params, fullPath } = this.$route;
      const isReservation = fullPath.includes('chantier');

      return {
        related_id: isReservation ? params.id : params.idItem,
        related_type: isReservation ? 'App\\Reservation' : 'App\\TransportItem',
      };
    },

    getWaitingFilesFormData() {
      const formData = new FormData();

      // add all waiting files to our formData
      Object.values(this.waitingFiles).forEach(waitingFile => {
        let key = `${waitingFile.kind}_${waitingFile.randomId}_files`;
        formData.append(
          `${key}[]`,
          waitingFile,
          waitingFile.newName || waitingFile.name,
        );
        formData.append('keys[]', key);
      });

      return formData;
    },

    /**
     * Ensure all daterange fields are valid
     */
    validateRangeFields() {
      const flatten = Object.values(this.questions).flatMap(
        question => question.fields,
      );

      let isValid = true;
      let names = [];

      Object.values(this.reports).forEach(report => {
        flatten.forEach(field => {
          if (field.kind !== questionKinds.DATERANGE) return;

          // if end before start, break
          if (
            report[field.property][0] &&
            report[field.property][1] &&
            dayjs(report[field.property][0]).isAfter(report[field.property][1])
          ) {
            names.push(field.name);
            isValid = false;
          }
        });
      });

      if (!isValid) {
        const message =
          'La date de fin doit être après la date de début pour le(s) champ(s) concerné(s) : ';

        this.sendToast(message + '(' + names.join(', ') + ')', 'danger');
      }

      return isValid;
    },

    resetData() {
      this.routeBack = null;
      this.couponId = null;
      this.questions = {};
      this.reports = {};
      this.missionDays = [];
      this.currentDay = null;
      this.opened = null;

      // files
      this.waitingFiles = [];
      this.attachmentsToDelete = [];
      this.attachmentsToUpdate = {};
    },

    async save(createCoupon = false) {
      if (this.sending) return;
      if (!this.validateRangeFields()) return;

      const result = utils.checkFilesSize(this.waitingFiles);
      if (!result.isOk) {
        utils.sendFilesErrorMessage(result.message);
        this.filesTooBig = true;

        return;
      }

      const formData = this.getWaitingFilesFormData();
      const dataToSend = this.getMissionRelatedData();
      dataToSend.reports = [];

      // dataToSend.reports = this.reports;
      const flatten = Object.values(this.questions).flatMap(
        question => question.fields,
      );

      Object.entries(this.reports).forEach(([day, value]) => {
        const line = { day };

        flatten.forEach(field => {
          // since daterange are stored as string in database we need to format them
          if (field.kind === this.questionKinds.DATERANGE) {
            if (!value[field.property].filter(v => !!v).length) {
              line[field.property] = null;
              return;
            }

            const formatted = value[field.property].map(v =>
              v ? dayjs(v).format('YYYY-MM-DD HH:mm') : '',
            );

            line[field.property] = `[${formatted.join(',')}]`;
          } else if (field.property) {
            line[field.property] = value[field.property] || null;

            // if we have a timestamp number, we need to add the timestamp
            if (field.kind === questionKinds.TIMESTAMP_NUMBER) {
              line[field.property + '_timestamp'] =
                value[field.property + '_timestamp'] || null;
            }

            return;
          }

          line.custom_questions = line.custom_questions || {};
          line.custom_questions[field.uniqid] =
            value.custom_questions[field.uniqid];
        });

        dataToSend.reports.push(line);
      });

      dataToSend.attachmentsToUpdate = this.attachmentsToUpdate;
      dataToSend.attachmentsToDelete = this.attachmentsToDelete;
      formData.append('report_data', JSON.stringify(dataToSend));

      this.sending = true;
      const loading = await loadingController.create({
        message: "En cours d'envoi...",
      });
      await loading.present();

      axios
        .post('/driver/mission-report', formData, await getParams())
        .then(() => {
          this.goToGoodPage(createCoupon);
          this.sendToast('Le rapport de mission a bien été enregistré');
          this.resetData();
        })
        .catch(() => {
          this.sendToast(
            "Une erreur est survenue lors de l'enregistrement du rapport de mission",
            'danger',
          );
        }).finally(() => {
          this.sending = false;
          loading.dismiss();
        });
    },

    async sendToast(message, color = 'success') {
      const toast = await toastController.create({
        message,
        duration: 3000,
        color,
        position: 'top',
      });
      await toast.present();
    },

    /**
     * Get value for field
     *
     * if not custom question, we get the value from the reports
     * else, we get the value from the custom_questions
     */
    getValueForField(field) {
      if (field.property) return this.reports[this.currentDay][field.property];

      return this.reports[this.currentDay].custom_questions[field.uniqid];
    },

    /**
     * BOOLEAN is a possible multiple type
     */
    onBooleanChange(newVal, field) {
      if (field.property) {
        this.reports[this.currentDay][field.property] = newVal;
        return;
      }

      this.reports[this.currentDay].custom_questions[field.uniqid] = newVal;
    },

    getDateTimeButtonLabel(property, key = null) {
      const reportForDay = this.reports[this.currentDay];
      const value =
        key === null ? reportForDay[property] : reportForDay[property][key];

      if (!value) return 'Définir';

      return dayjs(value).format('DD/MM/YYYY HH:mm');
    },

    setNow(day, property, index = null) {
      if (index === null) {
        if (this.reports[day][property]) return;
        this.reports[day][property] = dayjs().format(MACHINE_FORMAT);
        return;
      }

      if (this.reports[day][property][index]) return;
      this.reports[day][property][index] = dayjs().format(MACHINE_FORMAT);
    },

    setNull(day, property, index = null) {
      if (index === null) {
        this.reports[day][property] = null;
        return;
      }

      this.reports[day][property][index] = null;
    },

    open(title) {
      this.opened = this.opened === title ? null : title;
    },
  },
};
</script>

<style scoped lang="scss">
.bottom {
  position: sticky;
  // do not remove this version because we need it for safari
  position: -webkit-sticky;
  width: 100%;
  text-align: center;
  margin-left: auto;
}

ion-datetime-button::part(native),
.no-background::part(native) {
  background: #edeef0;
}

.custom-modal {
  --width: auto;
  --height: auto;
  --border-radius: 12px;
}

.custom-modal .modal-wrapper {
  width: auto;
  height: auto;
}

.custom-modal ion-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}

ion-textarea,
ion-input {
  border: 1px solid black;
  border-radius: 4px;
  --padding-bottom: 8px;
  --padding-end: 8px;
  --padding-start: 8px;
  --padding-top: 8px;
}

h2 {
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
}

h5 {
  margin-top: 24px;
}
</style>
