<template>
  <ion-page>
    <WhiteHeader
      :back-button="!!routeBack"
      :route-back="routeBack"
      :title="typeVue === 'create' ? 'Création d\'un BL' : oldRentalCoupon.name"
    />

    <ion-content class="ion-padding">
      <form>
        <ion-grid>
          <!-- v-if to avoid race condition -->
          <date
            v-if="start && end"
            v-model:start="start"
            v-model:end="end"
            v-model:start-default="startDefault"
            v-model:end-default="endDefault"
            v-model:old-rental-coupon-sending="oldRentalCouponSending"
            v-on:updateDateDependent="updateInformationsDateDependent"
          />

          <delivery-details
            v-model:materials="materials"
            v-model:rounds="rounds"
            v-model:tons-by-round="tonsByRound"
            v-model:allRoundsType="allRoundsType"
            v-model:toggle-quantity-tools="toggleQuantityTools"
            v-model:old-rental-coupon-sending="oldRentalCouponSending"
            :disabled="
              did_signature_source ||
              old_draw_signature_source ||
              no_signature_source
            "
          />

          <!-- v-show don't work directly on prestation so wrap in div -->
          <div v-show="!isPreliminaryStep">
            <prestation
              v-model:segment-activities="segmentActivities"
              v-model:segment-prestation="segmentPrestation"
              v-model:segment-bad-weather="segmentBadWeather"
              v-model:segment-maintenance="segmentMaintenance"
              v-model:times="times"
              v-model:old-rental-coupon-sending="oldRentalCouponSending"
              v-model:readable-date="readableDate"
              v-model:start="start"
              v-model:end="end"
              v-model:initialized="initialized"
              v-model:pricing-information="missionItem.pricing_information"
              v-model:amount-flat-rates="missionItem.amount_flat_rates"
              v-model:selected-modifiers="selectedModifiers"
              :modifiers="missionItem.modifiers"
            />
          </div>

          <ion-row class="title-part-padding-form-driver">
            <ion-col size="9" class="row-align-center">
              <ion-label
                class="title-part-form-driver"
                @click="toggleCharge = !toggleCharge"
                >Chargement<ion-badge
                  v-if="
                    missionItem.coupon_company &&
                    missionItem.coupon_company === 'source'
                  "
                  color="success"
                  class="ml-10"
                  >Client</ion-badge
                ></ion-label
              >
            </ion-col>
            <ion-col class="justify-content-end">
              <ion-toggle
                v-model="toggleCharge"
                mode="md"
                color="dark"
              ></ion-toggle>
            </ion-col>
          </ion-row>

          <client-details
            v-model:toggle-show="toggleCharge"
            v-model:builder_name="source_builder_name"
            v-model:segment-contact="segmentSourceContact"
            v-model:contact_id="source_contact_id"
            v-model:supervisors-builder="sourceSupervisorsBuilder"
            v-model:old-rental-coupon-sending="oldRentalCouponSending"
            v-model:contact_name="source_contact_name"
            v-model:contact_email="source_contact_email"
            v-model:builder_id="missionItem.source_id"
            v-model:worksite_name="source_worksite_name"
            v-model:type-vue="typeVue"
            v-model:status="status"
            :type-mission="typeMission"
            type-client="source"
            :coupon_company="missionItem.coupon_company"
          />

          <ion-row
            v-show="!isPreliminaryStep"
            class="title-part-padding-form-driver"
          >
            <ion-col size="9" class="row-align-center">
              <ion-label
                class="title-part-form-driver"
                @click="toggleDischarge = !toggleDischarge"
                >Déchargement<ion-badge
                  v-if="
                    missionItem.coupon_company &&
                    missionItem.coupon_company === 'target'
                  "
                  color="success"
                  class="ml-10"
                  >Client</ion-badge
                ></ion-label
              >
            </ion-col>
            <ion-col class="justify-content-end">
              <ion-toggle
                v-model="toggleDischarge"
                mode="md"
                color="dark"
              ></ion-toggle>
            </ion-col>
          </ion-row>

          <div v-show="!isPreliminaryStep">
            <client-details
              v-model:toggle-show="toggleDischarge"
              v-model:builder_name="target_builder_name"
              v-model:old-rental-coupon-sending="oldRentalCouponSending"
              v-model:worksite_name="target_worksite_name"
              v-model:type-vue="typeVue"
              :type-mission="typeMission"
              type-client="target"
              :coupon_company="missionItem.coupon_company"
              :source_signed="
                old_draw_signature_source ||
                (typeVue !== 'create' && no_signature_source)
              "
            />
          </div>

          <internal-references
            v-model:worksite_reference="worksite_reference"
            v-model:order_number="order_number"
          ></internal-references>
        </ion-grid>

        <ion-grid>
          <!-- I keep this div to keep git blame -->
          <div>
            <options
              ref="options"
              v-show="!isPreliminaryStep"
              v-model:toggle-comment="toggleComment"
              v-model:comments="comments"
              v-model:old-rental-coupon-sending="oldRentalCouponSending"
              :type-mission="typeMission"
              v-model:status="status"
              v-model:attachments="oldRentalCoupon.attachments"
              :files-too-big="filesTooBig"
            />

            <contact-selector
              v-if="
                missionItem.coupon_company &&
                missionItem.coupon_company === 'target'
              "
              v-show="!isPreliminaryStep"
              :contacts="targetSupervisorsBuilder"
              :limited-mode="oldRentalCouponSending"
              :mandatory-name="
                status === 'send' &&
                segmentTargetContact === 'new' &&
                !!target_contact_email
              "
              v-model:mode="segmentTargetContact"
              v-model:id="target_contact_id"
              v-model:name="target_contact_name"
              v-model:email="target_contact_email"
            ></contact-selector>

            <h5 v-if="isPreliminaryStep">Contact au chargement</h5>
            <contact-selector
              v-if="
                isPreliminaryStep ||
                (missionItem.coupon_company &&
                  missionItem.coupon_company === 'source')
              "
              :contacts="sourceSupervisorsBuilder"
              :limited-mode="oldRentalCouponSending"
              :mandatory-name="
                status === 'send' &&
                segmentSourceContact === 'new' &&
                !!source_contact_email
              "
              v-model:mode="segmentSourceContact"
              v-model:id="source_contact_id"
              v-model:name="source_contact_name"
              v-model:email="source_contact_email"
            ></contact-selector>
          </div>

          <signature
            ref="signature"
            v-model:did_signature_driver="did_signature_driver"
            v-model:canvas_signature_driver="canvas_signature_driver"
            v-model:old_draw_signature_driver="old_draw_signature_driver"
            v-model:did_signature_source="did_signature_source"
            v-model:canvas_signature_source="canvas_signature_source"
            v-model:old_draw_signature_source="old_draw_signature_source"
            v-model:no_signature_source="no_signature_source"
            v-model:did_signature_contact="did_signature_contact"
            v-model:canvas_signature_contact="canvas_signature_contact"
            v-model:old_draw_signature_contact="old_draw_signature_contact"
            v-model:no_signature_contact="no_signature_contact"
            v-model:signature_contact="oldRentalCoupon.signature_contact"
            v-model:old-rental-coupon-sending="oldRentalCouponSending"
            v-model:need_signature_source="missionItem.need_signature_source"
            :is-contact-view="!isPreliminaryStep"
            v-on:clearSignature="clearSignature"
            is-transport="true"
          />

          <internal-information
            ref="internalInformation"
            v-model:notes="notes"
            :type-vue="typeVue"
            :status="status"
            :files-too-big="filesTooBig"
            v-model:attachments="oldRentalCoupon.attachments"
          />

          <!--
          <information-text
            :status="status"
            :is-source-sendable="isSourceSendable"
            :is-contact-view="!isPreliminaryStep"
            kind="delivery"
          ></information-text>
          -->

          <ion-col>
            <ion-button
              color="tertiary"
              class="mt-10"
              strong="true"
              expand="block"
              type="button"
              @click.prevent="sendRentalCoupon(true)"
              :disabled="isCouponSendable || isSourceSendable"
              >{{
                oldRentalCoupon.status === 'send'
                  ? 'Enregistrer'
                  : 'Enregistrer en brouillon'
              }}</ion-button
            >

            <ion-button
              v-if="!isPreliminaryStep"
              color="success"
              class="mt-10"
              strong="true"
              expand="block"
              type="button"
              @click.prevent="sendRentalCoupon()"
              :class="{ disabled: !isCouponSendable }"
              >Envoyer au contact</ion-button
            >

            <ion-button
              v-else
              color="success"
              class="mt-10"
              strong="true"
              expand="block"
              type="button"
              @click.prevent="sendPdfToSource = true; sendRentalCoupon();"
              :disabled="!isSourceSendable"
              >Enregistrer et envoyer au chargement</ion-button
            >
          </ion-col>
        </ion-grid>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonLabel,
  IonToggle,
  IonBadge,
} from '@ionic/vue';
import Date from '@/components/driver/rentalCoupon/components/Date';
import ClientDetails from '@/components/driver/rentalCoupon/components/ClientDetails';
import Prestation from '@/components/driver/rentalCoupon/components/Prestation';
import Options from '@/components/driver/rentalCoupon/components/Options';
import ContactSelector from '@/components/driver/rentalCoupon/components/ContactSelector';
import Signature from '@/components/driver/rentalCoupon/components/Signature';
import InternalInformation from '@/components/driver/rentalCoupon/components/InternalInformation';
// import InformationText from '@/components/driver/rentalCoupon/components/InformationText';
import InternalReferences from '@/components/driver/rentalCoupon/components/InternalReferences';
import { mixinDataAllTypeRentalCoupon } from '@/components/driver/rentalCoupon/mixins/DataAllTypeRentalCoupon';
import { mixinDataTransportRentalCoupon } from '@/components/driver/rentalCoupon/mixins/DataTransportRentalCoupon';
import {
  getDetailMission,
  getMissionOrder,
} from '@/services/driver/missionsOrders';
import { fetchRentalCoupon } from '@/services/driver/rentalCoupon';
import { getInfo } from '@/services/users';
import DeliveryDetails from '@/components/driver/rentalCoupon/components/DeliveryDetails';
import WhiteHeader from '@/components/driver/header/WhiteHeader';
import utils from '@/services/utils/Utils';

const dayjs = require('dayjs');
require('dayjs/locale/fr');
dayjs.locale('fr');

const ROUNDS_MAX = 20;
const ALL_ROUNDS_TYPE = [
  {
    name: 'À la journée',
    value: '1',
  },
  {
    name: 'Au tour',
    value: '1',
    isCustom: true,
  },
].concat(
  Array.from({ length: ROUNDS_MAX }, (_, i) => ({
    name: `${i + 1} tour${i > 0 ? 's' : ''}`,
    value: `${i + 1}`,
  })),
);

export default {
  name: 'RentalDelivery',

  components: {
    WhiteHeader,
    DeliveryDetails,
    IonPage,
    IonContent,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonLabel,
    IonToggle,
    IonBadge,
    InternalInformation,
    // InformationText,
    InternalReferences,
    Signature,
    Options,
    ContactSelector,
    Prestation,
    ClientDetails,
    Date,
  },

  mixins: [mixinDataAllTypeRentalCoupon, mixinDataTransportRentalCoupon],

  data() {
    return {
      // Do not forget a lot of data is set in mixins
      materials: [],
      rounds: '',
      tonsByRound: {},
      allRoundsType: JSON.parse(JSON.stringify(ALL_ROUNDS_TYPE)),
    };
  },

  async mounted() {
    // Get informations on the mission
    this.mission = await getMissionOrder(
      this.$route.params.id,
      this.$route.params.idRide,
      this.typeMission,
      this.$route.params.idRentalCoupon,
    );
    for (const delivery in this.mission['transport_items']) {
      // first case : we have an item, so it's the id of the item
      // second case : we don't have an item, so it's the id of the rental_coupon
      if (delivery === this.$route.params.idItem || delivery === this.$route.params.idRentalCoupon) {
        this.missionItem = this.mission['transport_items'][delivery];
      }
    }

    if (!this.missionItem.pricing_information) {
      utils.routeForbidden(this.$router, this.$route, 'livraison');
    } else {
      this.times.prestation.type = this.getPrestationType(this.missionItem);
    }

    this.missionDetail = await getDetailMission(
      this.typeMission,
      this.$route.params.id,
      this.$route.params.idItem,
      this.$route.params.idRentalCoupon,
    );

    const { userData } = await getInfo();
    this.user = userData;

    this.startDefault = this.mission.start;
    this.endDefault = this.mission.end;
    this.source_builder_name = this.missionItem.source;
    this.target_builder_name = this.missionItem.target;

    if (this.typeVue !== 'create') {
      this.oldRentalCoupon = await fetchRentalCoupon(
        this.$route.params.idRentalCoupon,
      );

      if (this.oldRentalCoupon.status === 'send') {
        this.oldRentalCouponSending = true;
      }

      this.start = this.oldRentalCoupon.start;
      this.end = this.oldRentalCoupon.end;
      if (this.oldRentalCoupon.start < this.startDefault) {
        this.startDefault = this.oldRentalCoupon.start;
      }
      if (this.oldRentalCoupon.end > this.endDefault) {
        this.endDefault = this.oldRentalCoupon.end;
      }

      this.pricing_fuel = this.oldRentalCoupon.pricing_fuel;
      this.pricing_driver = this.oldRentalCoupon.pricing_driver;
      this.materials = this.oldRentalCoupon.materials;
      this.comments = this.oldRentalCoupon.comments;

      this.old_draw_signature_contact = this.oldRentalCoupon.signature_contact;
      this.old_draw_signature_driver = this.oldRentalCoupon.signature_driver;
      this.old_draw_signature_source = this.oldRentalCoupon.signature_source;
      this.no_signature_contact = this.oldRentalCoupon.no_signature_contact;
      this.no_signature_source = this.oldRentalCoupon.no_signature_source;

      this.setToggle(this.typeMission);
      this.setModifiersKeys(this.oldRentalCoupon.modifiers);
    } else {
      this.start = this.mission.start;
      this.end = this.mission.end;

      this.pricing_fuel = this.missionItem.pricing_fuel;
      this.pricing_driver = this.missionItem.pricing_driver;

      this.materials = this.missionItem.materials;
    }

    this.source_worksite_name = this.setWorksiteTextForm(
      this.oldRentalCoupon.source_worksite_name,
      this.missionItem.source_location_name,
      this.missionItem.source_location_address,
      this.missionItem.source_location_city,
      null,
    );
    this.target_worksite_name = this.setWorksiteTextForm(
      this.oldRentalCoupon.target_worksite_name,
      this.missionItem.target_location_name,
      this.missionItem.target_location_address,
      this.missionItem.target_location_city,
      null,
    );

    await this.setSourceSupervisorsInformationForm();
    await this.setTargetSupervisorsInformationForm();
    this.setRounds();
    this.setMaterialsByTons();
    this.setInformationsDateDependent();
    this.setInternalReferences();

    this.onViewEnter(); // initializationSignatureData

    if (this.typeVue === 'create') {
      this.setSelectedModifiers(this.missionItem.modifiers);
    }

    // open quantity part if needed
    this.toggleQuantityTools = this.isPreliminaryStep;
  },

  methods: {
    setRounds() {
      let roundName;

      this.typeVue !== 'create'
        ? (roundName = this.oldRentalCoupon.rounds)
        : (roundName = this.missionItem.rounds);

      // try to get good round if not custom
      this.rounds = this.allRoundsType.filter(round => {
        return round.name === roundName;
      })[0];

      // if custom, add it to the array
      if (!this.rounds) {
        this.rounds = {
          name: roundName,
          value: '1',
          isCustom: true,
        };

        this.allRoundsType.push(this.rounds);
      }
    },

    setMaterialsByTons() {
      if (this.typeVue !== 'create') {
        this.tonsByRound = this.oldRentalCoupon.tons_by_round;
        return;
      }
      if (this.rounds.name === 'À la journée') {
        this.tonsByRound[0] = {
          name: `Total`,
          value: null,
        };
        return;
      }
      for (let i = 0; i <= this.rounds.value - 1; i++) {
        this.tonsByRound[i] = {
          name: `Tour ${i + 1}`,
          value: null,
        };
      }
    },

    async sendRentalCoupon(force = false) {
      if (!this.isCouponSendable && !force && !this.sendPdfToSource) {
        await this.sendCouponNotSendableToast();
        return;
      }

      const couponCompany = this.missionItem.coupon_company;

      if (
        this.status === 'send' &&
        couponCompany &&
        !this[`${couponCompany}_contact_email`] &&
        !this[`${couponCompany}_contact_phone`] &&
        (await this.sendWithNoEmail())
      ) {
        return;
      }

      if (this.typeVue !== 'create' && this.oldRentalCoupon.status !== 'save') {
        this.rentalCoupon = this.oldRentalCoupon;

        this.rentalCoupon.source_contact_email = this.source_contact_email;
        this.rentalCoupon.target_contact_email = this.target_contact_email;
        this.rentalCoupon.notes = this.notes;
      } else {
        const { userData } = await getInfo();

        this.rentalCoupon = {
          ride_id: this.mission.id,
          delivery_id: this.mission.id_transport,
          item_id: this.$route.params.idItem,
          driver_id: userData.agent_id,
          machine_id: this.mission.machine_id,

          status: this.status,
          start: this.start,
          end: this.end,

          materials: this.materials,
          rounds: this.rounds.name,
          tons_by_round: this.tonsByRound,

          source_builder_id: null,
          source_builder_name: null,
          source_contact_id: null,
          source_contact_name: null,
          source_contact_email: this.source_contact_email,
          source_worksite_id: null,
          source_worksite_name: null,

          target_builder_id: null,
          target_builder_name: null,
          target_contact_id: null,
          target_contact_name: null,
          target_contact_email: this.target_contact_email,
          target_worksite_id: null,
          target_worksite_name: null,

          prestation_times: this.completeTimes(this.times.prestation),
          bad_weather_times: this.completeTimes(this.times.badWeather),
          maintenance_times: this.completeTimes(this.times.maintenance),
          pricing_fuel: this.pricing_fuel,
          pricing_driver: this.pricing_driver,
          comments: this.comments,
          signature_contact: null,
          signature_driver: null,
          no_signature_contact: this.no_signature_contact,
          no_signature_source: this.no_signature_source,
          need_signature_source: this.missionItem.need_signature_source,
          coupon_company: this.missionItem.coupon_company,
          custom_coupon_company_id: this.missionItem.custom_coupon_company_id,
          custom_coupon_company_manual: this.missionItem.custom_coupon_company_manual,
          billing_company_id: this.missionItem.billing_company_id,

          notes: this.notes,
          worksite_reference: this.worksite_reference,
          order_number: this.order_number,

          pricing_information: this.mission.pricing_information,
          amount_flat_rates: this.mission.amount_flat_rates,
          added_modifiers: Object.values(this.selectedModifiers),
          custom_articles: this.missionItem.custom_articles,
          custom_modifiers: this.missionItem.custom_modifiers,
        };

        this.completeSourceBuilder();
        this.completeSourceContact();
        this.completeSourceWorksite();
        this.completeTargetBuilder();
        this.completeTargetContact();
        this.completeTargetWorksite();

        this.completeMachineCounteur();
        this.saveSignature('contact');
        this.saveSignature('driver');
        this.saveSignature('source');
      }

      if (this.typeVue !== 'create') {
        this.rentalCoupon['id'] = this.oldRentalCoupon.id;
      }

      const optionsVue = this.$refs.options;
      const internalInformationVue = this.$refs.internalInformation;
      this.rentalCoupon.attachmentsToDelete = [
        ...(optionsVue ? optionsVue.getAttachmentsToDelete() : []),
        ...internalInformationVue.getAttachmentsToDelete(),
      ].join('|');
      this.rentalCoupon.attachmentsToUpdate = {
        ...(optionsVue ? optionsVue.getAttachmentsToUpdate() : []),
        ...internalInformationVue.getAttachmentsToUpdate(),
      };

      const res = await this.send([
        ...(optionsVue ? optionsVue.getWaitingFiles() : []),
        ...internalInformationVue.getWaitingFiles(),
      ]);

      // if go back to "listeBonLocation" month will still be correct because the component is not unmounted so data is not reset
      let path = '/listeBonLocation';
      const query = {};

      // if not on "listeBonLocation", go back to associated delivery page
      if (
        this.$router.options.history.state.back &&
        !this.$router.options.history.state.back.includes(path)
      ) {
        path = `/livraison/${this.rentalCoupon.delivery_id}/${this.$route.params.idRide}/${this.$route.params.idItem}`;
      } else {
        query.forceRefresh = true;
      }

      if (res && res.status && res.status === 'ok') {
        if (optionsVue) {
          optionsVue.resetFilesInformation();
        }
        internalInformationVue.resetFilesInformation();

        await this.$router.replace({ path, query });
      }
    },

    setModifiersKeys(modifiers) {
      modifiers.forEach((modifier) => {
        this.selectedModifiers[modifier.name] = modifier;
      });
    },
  },
};
</script>

<style scoped>
h5 {
  font-weight: bold;
}
</style>
